import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { getImage } from "gatsby-plugin-image";

import Background from "../Background/Background";
import Slider from "../Slider/SliderStandard";

const StyledSlider = styled.div`
  .slick-prev,
  .slick-next {
    ${tw`h-10 w-10 bg-primary-400 bg-opacity-20`}
    &:hover {
      ${tw`bg-primary-400`}
    }
  }
  .slick-prev {
    ${tw``}
  }
  .slick-next {
    ${tw``}
  }
  .slick-dots {
    ${tw`relative flex! items-center justify-center space-x-3 mt-20 md:mt-10 mb-0`}
    li {
      ${tw`h-2.5 w-2.5 rounded-full bg-white md:bg-gray-400 mr-0 transition-all duration-300 ease-linear`}
      &.slick-active {
        ${tw`bg-primary-400`}
      }
    }
  }
`;

const Testimonial = ({ className, headingLevel }) => {
  const data = useStaticQuery(graphql`
    {
      backgroundDesktop: file(
        relativePath: { eq: "repeating/testimonials/Testimonial desktop.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      backgroundMobile: file(
        relativePath: { eq: "repeating/testimonials/Testimonial Mobile.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const testimonials = [
    {
      quote:
        "It was a pleasure to work with Mark. He was a pillar of calm in what was a somewhat whirlwind experience for us... I know he cared about our situation like it was his own, and therefore worked really hard on our behalf.",
      name: "Michael and Cheryl",
    },
    {
      quote:
        "My wife and I have worked with Mark twice, first on the original mortgage and then again to refinance our mortgage. Mark was able to offer us very competitive rates both times and was a pleasure to work with throughout the financing process. Throughout the process, he was always prompt with updates on progress, and always willing to spend time answering any questions that we had.",
      name: "Tom and Kaleigh",
    },
    {
      quote:
        "My husband and I were extremely impressed with Thistle Financial's excellent service. Mark truly has a personal commitment to providing excellent service.",
      name: "Joe and Erin",
    },
  ];

  const HeadingTag = headingLevel || "h2";

  const backgroundImages = [
    getImage(data.backgroundDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.backgroundMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Background
      backgroundImages={backgroundImages}
      padding="pt-24 md:pt-44 pb-8 md:pb-40"
      className={`${className || "mb-24 md:mb-32"}`}
    >
      <header className="mb-10 md:mb-12 text-center">
        <HeadingTag>Hear From Folks Just Like You</HeadingTag>
      </header>

      <StyledSlider className="relative">
        <Slider>
          {testimonials.map((testimonial, i) => {
            return (
              <div key={i}>
                <blockquote className="md:max-w-2xl mx-auto text-center md:w-3/4">
                  <q className="md:text-xl">{testimonial.quote}</q>
                  <footer className="mt-12 mx-auto">
                    <div>
                      <cite className="not-italic">
                        <div className="text-primary-600 uppercase tracking-widest mb-2">
                          <span className="font-semibold">
                            {testimonial.name}
                          </span>
                          {/* <span>{testimonial.company}</span> */}
                        </div>
                        {/* <GatsbyImage image={testimonial.platform} /> */}
                      </cite>
                    </div>
                  </footer>
                </blockquote>
              </div>
            );
          })}
        </Slider>
      </StyledSlider>
    </Background>
  );
};

export default Testimonial;
