import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";
import Background from "../Background/Background";

const CTA = ({ heading, headingLevel, subtext, className }) => {
  const data = useStaticQuery(graphql`
    {
      backgroundDesktop: file(
        relativePath: { eq: "repeating/CTA/CTA desktop.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      backgroundMobile: file(
        relativePath: { eq: "repeating/CTA/CTA mobile.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const backgroundImages = [
    getImage(data.backgroundDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.backgroundMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Background
      backgroundImages={backgroundImages}
      padding="pt-88 md:pt-18 pb-12 md:pb-22"
    >
      <header className="max-w-[704px] text-center md:text-left">
        <HeadingTag className="text-mobile-6xl md:text-6xl md:mb-6">
          {heading || ["Ready to Begin?"]}
        </HeadingTag>

        <p className="mb-8 md:mb-6 text-base">
          {subtext ||
            "We’re excited to get to know you and start looking at options that’ll help you realize your dream. Contact your Minnesota mortgage broker today!"}
        </p>
      </header>

      <div className="flex items-center space-x-4">
        <ButtonGhost
          modal="modal-contact"
          text="Contact Us"
          className="w-full md:w-auto"
        />
        <ButtonSolid
          href="/request-rates/"
          text="Request Rates"
          className="w-full md:w-auto"
        />
      </div>
    </Background>
  );
};

export default CTA;
